import React, { useState, useEffect, useRef, useContext } from "react"
import { Link } from "gatsby"
import axios from "axios"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import SiteContext from "../context/siteContext"

import {
  Container,
  makeStyles,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Grid,
  useMediaQuery,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Backdrop,
} from "@material-ui/core"

import SEO from "../components/seo"
import Sigin from "../components/signin"
import PageTitle from "../components/page-title"
import Favorite from "../components/favorite-btn"
import Breadcrumbs from "../components/breadcrumbs"

import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import ShoppingCartIcon from "../../static/images/Icons/ShoppingCart.svg"
import BookImage from "../../static/images/book-image.png"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(9),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  cartItemsTable: {
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  bookImage: {
    border: "3px solid #000",
    borderRadius: 10,
    maxWidth: "80%",
  },
  textCenter: {
    textAlign: "center",
  },
  bookImageMobile: {
    maxWidth: "120px",
    width: "100%",
  },
  BookTitleBtnsInner: {
    height: "100%",
    position: "relative",
    minHeight: 150,
    "& a": {
      textDecoration: "none",
    },
  },
  BookDeleteItemBtn: {
    position: "absolute",
    left: 0,
    bottom: 20,
    "& button": {
      borderRadius: 10,
    },
  },
  continueBtn: {
    borderRadius: 10,
    paddingRight: 40,
    paddingLeft: 40,
  },
  navigateInCartBtns: {
    marginTop: "3rem",
    textAlign: "center",
  },
  cartItemTbodyTh: {
    width: "calc(100% - 47%)",
  },
  checkoutBtn: {
    backgroundColor: "#003e96",
    borderRadius: 10,
    color: "#fff",
    paddingRight: 40,
    paddingLeft: 40,
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#065dd8",
    },
  },
  cartItemsTableText: {
    fontSize: 18,
    fontWeight: 600,
  },
  cartItemsTableTextHeader: {
    fontSize: 18,
  },
  textRight: {
    textAlign: "right",
  },
  BookDeleteItemBtnMobile: {
    marginTop: "1rem",
    "& button": {
      borderRadius: 10,
    },
  },
  cartItemDetails: {
    marginTop: theme.spacing(1),
  },
  cartItemDetailsTitle: {
    color: "#003e96",
    fontWeight: "600",
  },
  notSignedIn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: "26vh",
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },
  cartItemsCountPaper: {
    maxWidth: "50%",
    margin: "0 auto",
  },
  bookQuantityMobile: {
    display: "inline-block",
    marginLeft: theme.spacing(1),
    "& .MuiButtonBase-root": {
      padding: "4px 15px",
    },
  },
  bookQuantityBtnsMobile: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  tableRow: {
    borderBottom: "2px solid Gray",
  },
  tableRowMobile: {
    marginBottom: 16,
  },
  favButton: {
    display: "inline",
    "& img": {
      maxWidth: 24,
    },
    "&>button": {
      marginLeft: 6,
      padding: "0px 20px",
    },
  },
}))

function ButtonLink(props) {
  const { icon, primary, to } = props
  const classes = useStyles()

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  )

  return (
    <Button
      component={renderLink}
      className={classes.checkoutBtn}
      activeClassName={classes.activeMenu}
      startIcon={icon}
    >
      {primary}
    </Button>
  )
}

ButtonLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

const CartItems = ({ pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const { pLang } = pageContext
  const timerRef = useRef()
  const [loading, setLoading] = useState(false)
  const [cartCount, setCartCount] = useState(0)
  const [loadingContent, setLoadingContent] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const [currencyRateState, setCurrencyRate] = useState(1)
  const [cartItemContent, setCartItemContent] = useState([])
  const { setIsActiveCart, setCartItemContentLenght } = useContext(SiteContext)
  // const {
  //   isActiveCart,
  //   setIsActiveCart,
  //   cartItemContentLenght,
  //   setCartItemContentLenght,
  // } = useContext(SiteContext)

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "cart-items",
        `/${i18n.language}/cart-items`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "cart-items",
          `/${i18n.language}/cart-items`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/cart-items`
      }
    })
  }, [i18n, pLang])

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
        setCurrencyRate(windowGlobal.sessionStorage.getItem("currencyRate"))
      }
    }
  }, [])

  const currencyIcon = currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>

  useEffect(
    () => () => {
      clearTimeout(timerRef.current)
    },
    []
  )

  const breadcrumbs = [
    {
      text: t("cartItems.pageTitle"),
    },
  ]

  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }

  const handleClickBadgeReduce = async itemId => {
    setLoading(true)
    let newCartItemContent = [...cartItemContent]
    let newCartItem = newCartItemContent
      .filter(item => item.id === itemId)
      .map(item => {
        return { ...item, quantity: Math.max(item.quantity - 1, 0) }
      })

    const res = await axios.put(
      `${process.env.GATSBY_API_URL}/cart-items/${itemId}`,
      { quantity: newCartItem[0].quantity },
      { headers: { Authorization: `Bearer ${jwtForProfile}` } }
    )
    let finalCartItemContent = newCartItemContent.map(item => {
      if (item.id === itemId) {
        return newCartItem[0]
      } else {
        return item
      }
    })

    setCartCount(cartCount + 1)
    setCartItemContent(finalCartItemContent)
    setLoading(false)
  }

  const handleClickBadgeIncrease = async itemId => {
    setLoading(true)
    let newCartItemContent = [...cartItemContent]
    let newCartItem = newCartItemContent
      .filter(item => item.id === itemId)
      .map(item => {
        return { ...item, quantity: Math.max(item.quantity + 1, 0) }
      })

    const res = await axios.put(
      `${process.env.GATSBY_API_URL}/cart-items/${itemId}`,
      { quantity: newCartItem[0].quantity },
      { headers: { Authorization: `Bearer ${jwtForProfile}` } }
    )

    let finalCartItemContent = newCartItemContent.map(item => {
      if (item.id === itemId) {
        return newCartItem[0]
      } else {
        return item
      }
    })

    setCartCount(cartCount - 1)
    setCartItemContent(finalCartItemContent)
    setLoading(false)
  }

  const handleDeleteItem = cartItemId => {
    setIsDisabled(true)
    axios
      .delete(`${process.env.GATSBY_API_URL}/cart-items/${cartItemId}`, {
        headers: { Authorization: `Bearer ${jwtForProfile}` },
      })
      .then(() => {
        setIsDisabled(false)
        setCartItemContent(
          cartItemContent.filter(item => item.id !== cartItemId)
        )
      })
  }

  useEffect(() => {
    setIsActiveCart(cartItemContent.length > 0 ? true : false)
    setCartItemContentLenght(cartItemContent.length)
  }, [handleClickBadgeReduce, cartItemContent])

  useEffect(() => {
    if (jwtForProfile) {
      axios
        .get(`${process.env.GATSBY_API_URL}/get-cart-items-by-type/paper`, {
          headers: {
            Authorization: `Bearer ${jwtForProfile}`,
            "Accept-Language": i18n.language,
            // "currency": localCurrency,
          },
        })
        .then(res => {
          setCartItemContent(res.data.items)
          setCartItemContentLenght(res.data.items.length)
          setLoadingContent(false)
        })
    }
  }, [cartCount, i18n, jwtForProfile])

  const isMobile = useMediaQuery("(max-width:960px)")

  return (
    <>
      <SEO title={t("cartItems.pageTitle")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("cartItems.pageTitle")} image={ShoppingCartIcon} />
      {/* {jwtForProfile ? ( */}
      <Box component="div" display={jwtForProfile ? `block` : `none`}>
        <Container className={classes.mainContainer}>
          {!isMobile ? (
            <TableContainer component={Paper}>
              <Table
                className={classes.cartItemsTable}
                aria-label="simple table"
              >
                {cartItemContent.length !== 0 && (
                  <TableHead>
                    <TableRow>
                      <TableCell> </TableCell>
                      <TableCell
                        align="center"
                        className={classes.cartItemsTableTextHeader}
                      >
                        {t("cartItems.unitPrice")}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.cartItemsTableTextHeader}
                      >
                        {t("cartItems.quantity")}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.cartItemsTableTextHeader}
                      >
                        {t("cartItems.total")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody className={classes.tableBody}>
                  {cartItemContent.length !== 0 ? (
                    cartItemContent.map((item, index) => (
                      <TableRow key={item.id} className={classes.tableRow}>
                        <TableCell
                          className={classes.cartItemTbodyTh}
                          component="th"
                          scope="item"
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              className={classes.textCenter}
                            >
                              <Link
                                to={`/${i18n.language}/books/${item.book.bookID}`}
                              >
                                <img
                                  className={classes.bookImage}
                                  alt="book"
                                  src={
                                    item.book.image
                                      ? `${process.env.GATSBY_API_URL}` +
                                        item.book.image.url
                                      : BookImage
                                  }
                                />
                              </Link>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              sm={8}
                              md={8}
                              className={classes.BookTitleBtns}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                className={classes.BookTitleBtnsInner}
                              >
                                <Box>
                                  <Link
                                    to={`/${i18n.language}/books/${item.book.bookID}`}
                                  >
                                    <Typography color="primary" variant="h6">
                                      {item.book.bookTitle}
                                    </Typography>
                                  </Link>
                                </Box>
                                <Box
                                  alignSelf="flex-end"
                                  className={classes.BookDeleteItemBtn}
                                >
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={isDisabled}
                                    className={classes.button}
                                    startIcon={<DeleteForeverIcon />}
                                    onClick={() =>
                                      handleDeleteItem(item.id)
                                    }
                                  >
                                    {t("cartItems.delete")}
                                  </Button>
                                  <div className={classes.favButton}>
                                    <Favorite id={item.book.bookID} />
                                  </div>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cartItemsTableText}
                        >
                          {item.itemDiscountPrice
                            ? parseFloat(
                                item.itemDiscountPrice * currencyRateState
                              ).toFixed(2)
                            : parseFloat(
                                item.itemPrice * currencyRateState
                              ).toFixed(2)}
                          {currencyIcon}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cartItemsTableText}
                        >
                          <Paper
                            variant="outlined"
                            square
                            className={classes.cartItemsCountPaper}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-evenly"
                              alignItems="center"
                              className={classes.countItemBox}
                            >
                              <Box>
                                <IconButton
                                  color="primary"
                                  aria-label="add to shopping cart"
                                  onClick={() =>
                                    handleClickBadgeReduce(
                                      item.id,
                                      item.quantity
                                    )
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </Box>
                              <Box className={classes.cartItemsCountnumber}>
                                {item.quantity}
                                <Backdrop
                                  className={classes.backdrop}
                                  open={loading}
                                >
                                  <CircularProgress color="inherit" />
                                </Backdrop>
                              </Box>
                              <Box>
                                <IconButton
                                  color="primary"
                                  aria-label="add to shopping cart"
                                  onClick={() =>
                                    handleClickBadgeIncrease(
                                      item.id,
                                      item.quantity
                                    )
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Paper>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cartItemsTableText}
                        >
                          {item.itemDiscountPrice
                            ? parseFloat(
                                item.itemDiscountPrice *
                                  item.quantity *
                                  currencyRateState
                              ).toFixed(2)
                            : parseFloat(
                                item.itemPrice *
                                  item.quantity *
                                  currencyRateState
                              ).toFixed(2)}
                          {currencyIcon}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center">
                        {loadingContent ? (
                          <Typography variant="subtitle1">
                            {t("myProfile.loading")}
                          </Typography>
                        ) : (
                          t("cartItems.noContent")
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              {cartItemContent.map(item => (
                <Grid
                  key={item.id}
                  container
                  spacing={3}
                  className={`${classes.tableRow} ${classes.tableRowMobile}`}
                >
                  <Grid item xs={4} className={classes.textRight}>
                    <img
                      className={classes.bookImageMobile}
                      alt="mobile book"
                      src={
                        item.book.image
                          ? `${process.env.GATSBY_API_URL}` + item.book.image.url
                          : BookImage
                      }
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="subtitle1"
                      component="h2"
                      className={classes.cartItemDetailsTitle}
                    >
                      {item.book.bookTitle}
                    </Typography>
                    <Grid container className={classes.cartItemDetails}>
                      <Grid item xs={12} className={classes.BookTitleBtns}>
                        {t("cartItems.unitPrice")}:{" "}
                        {item.itemDiscountPrice
                          ? parseFloat(
                              item.itemDiscountPrice * currencyRateState
                            ).toFixed(2)
                          : parseFloat(
                              item.itemPrice * currencyRateState
                            ).toFixed(2)}
                        {currencyIcon}
                        {/* {item.itemDiscountPrice
                              ? item.itemDiscountPrice
                              : item.itemPrice} */}
                      </Grid>
                      <Grid item xs={12} className={classes.BookTitleBtns}>
                        {t("cartItems.quantity")}:
                        <div className={classes.bookQuantityMobile}>
                          <Paper variant="outlined" square>
                            <Box
                              display="flex"
                              justifyContent="space-evenly"
                              alignItems="center"
                              className={classes.countItemBox}
                            >
                              <Box>
                                <IconButton
                                  color="primary"
                                  className={classes.bookQuantityBtnsMobile}
                                  aria-label="add to shopping cart"
                                  onClick={() =>
                                    handleClickBadgeReduce(
                                      item.id,
                                      item.quantity
                                    )
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </Box>
                              <Box className={classes.cartItemsCountnumber}>
                                {item.quantity}
                                <Backdrop
                                  className={classes.backdrop}
                                  open={loading}
                                >
                                  <CircularProgress color="inherit" />
                                </Backdrop>
                              </Box>
                              <Box>
                                <IconButton
                                  color="primary"
                                  aria-label="add to shopping cart"
                                  onClick={() =>
                                    handleClickBadgeIncrease(
                                      item.id,
                                      item.quantity
                                    )
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Paper>
                        </div>
                      </Grid>
                      <Grid item xs={12} className={classes.BookTitleBtns}>
                        {t("cartItems.total")}:{" "}
                        {item.itemDiscountPrice
                          ? parseFloat(
                              item.itemDiscountPrice *
                                item.quantity *
                                currencyRateState
                            ).toFixed(2)
                          : parseFloat(
                              item.itemPrice * item.quantity * currencyRateState
                            ).toFixed(2)}
                        {currencyIcon}
                        {/* {item.itemDiscountPrice
                          ? item.itemDiscountPrice * item.quantity
                          : item.itemPrice * item.quantity} */}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.BookDeleteItemBtnMobile}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          disabled={isDisabled}
                          startIcon={<DeleteForeverIcon />}
                          onClick={() => handleDeleteItem(item.id)}
                        >
                          {t("cartItems.delete")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
          <div className={`${classes.navigateInCartBtns}`}>
            {cartItemContent.length !== 0 && (
              <ButtonLink
                variant="contained"
                primary={t("cartItems.continueBtn")}
                to={`/${i18n.language}/checkout`}
                className={`${classes.continueBtn}`}
              />
            )}
          </div>
        </Container>
      </Box>
      {/* ) : ( */}
      <Box component="div" display={!jwtForProfile ? `block` : `none`}>
        <Container className={classes.notSignedIn}>
          <Typography variant="subtitle1">
            {t("myProfile.noPremissions")}
          </Typography>
          <br />
          <Sigin />
        </Container>
      </Box>
      {/* )} */}
    </>
  )
}
export default CartItems
